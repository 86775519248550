import './FunctionDetail.scss'

import loadable from '@loadable/component'
import Layout from 'EasyProjectGatsby/components/core/Layout'
import { graphql, PageProps } from 'gatsby'
import React, { FunctionComponent } from 'react'
import WidgetBuilder from 'Shared/components/builders/WidgetBuilder'
import PuxMediaSelector, {
  PuxMediaSelectorType,
} from 'Shared/components/content/mediaImage/PuxMediaSelector'
import EasySoftwareBreadcrumbs, {
  BreadcrumbItem,
} from 'Shared/components/EasySoftwareBreadcrumbs/EasySoftwareBreadcrumbs'
import PuxColumns, {
  PuxMainColumn,
  PuxSideColumn,
} from 'Shared/components/PuxColumns/PuxColumns'
import { localizedPathType } from 'Shared/components/PuxLanguageSelector/PuxLanguageSelector'
import { PuxVideoType } from 'Shared/components/PuxVideo/PuxVideo'
import PuxWysiwyg, {
  PuxWysiwygHtmlBodyType,
} from 'Shared/components/PuxWysiwyg/PuxWysiwyg'
import { WidgetPuxSectionType } from 'Shared/components/widgets/WidgetPuxSection'
import PuxMetaTags, { PuxMetaTagsType } from 'Shared/utils/PuxMetaTags'

const PuxLightboxGallery = loadable(
  () => import(`Shared/components/PuxLightboxGallery/PuxLightboxGallery`)
)
const PuxVideo = loadable(() => import(`Shared/components/PuxVideo/PuxVideo`))

export interface FunctionType {
  contentType: string
  path: string
  contentItemId: string
  contentItemVersionId: string
  localization: {
    localizations: localizedPathType[]
  }
  displayText: string
  functionPerex: PuxWysiwygHtmlBodyType
  functionContent: PuxWysiwygHtmlBodyType
  functionImage: PuxMediaSelectorType
  functionGallery: PuxMediaSelectorType
  puxVideo: PuxVideoType
}

export interface FunctionDetailType extends PageProps {
  data: {
    orchard: {
      easySoftwareFunction: [FunctionType]
    }
  }
  pageContext: {
    pagePath: string
    metaTags: PuxMetaTagsType
    breadcrumbs: [BreadcrumbItem]
    sideCardWidgets: any
    widgets: {
      widgetZone: [WidgetPuxSectionType]
    }
  }
}

export const query = graphql`
  query($pagePath: String = "") {
    orchard {
      easySoftwareFunction(first: 1, where: { path: $pagePath }) {
        contentType
        path
        contentItemId
        displayText
        localization {
          localizations {
            ... on Orchard_EasySoftwareFunction {
              path
              published
              localization {
                culture
              }
            }
          }
        }
        functionPerex {
          html
        }
        functionContent {
          html
        }
        functionImage {
          mediaSelectorImage {
            resizePaths(width: 1920, mode: "Min", quality: 75)
            resizePathsMobile(width: 768, mode: "Min", quality: 75)
            resizePathsTablet(width: 1200, mode: "Min", quality: 75)
            texts
          }
        }
        functionGallery {
          mediaSelectorImage {
            resizePaths(width: 1920, mode: "Min", quality: 75)
            resizePathsMobile(width: 768, mode: "Min", quality: 75)
            resizePathsTablet(width: 1200, mode: "Min", quality: 75)
          }
        }
        puxVideo {
          videoStructuredDataContentUrl
          videoStructuredDataDescription
          videoStructuredDataName
          videoStructuredDataUploadDate
          videoYTID {
            html
          }
        }
      }
    }
  }
`

const rootClass = `function`

const FunctionDetail: FunctionComponent<FunctionDetailType> = (props) => {
  const pageData = props.data.orchard.easySoftwareFunction[0]
  const breadCrumbsData = props.pageContext.breadcrumbs

  function renderMediaComponent() {
    if (
      typeof pageData.puxVideo.videoYTID?.html !== `string` ||
      pageData.puxVideo.videoYTID?.html === ``
    ) {
      return <PuxMediaSelector {...pageData.functionImage} />
    }
    if (
      typeof pageData.puxVideo.videoYTID?.html === `string` &&
      pageData.puxVideo.videoYTID?.html !== ``
    ) {
      return (
        <PuxVideo
          videoStructuredDataDescription={
            pageData.puxVideo.videoStructuredDataDescription
          }
          videoStructuredDataContentUrl={
            pageData.puxVideo.videoStructuredDataContentUrl
          }
          videoStructuredDataName={pageData.puxVideo.videoStructuredDataName}
          videoStructuredDataUploadDate={
            pageData.puxVideo.videoStructuredDataUploadDate
          }
          videoThumbnail={pageData.functionImage.mediaSelectorImage}
          videoYTID={pageData.puxVideo.videoYTID}
        />
      )
    }

    return null
  }

  return (
    <Layout localizedPath={pageData.localization?.localizations}>
      <EasySoftwareBreadcrumbs items={breadCrumbsData} />
      <PuxMetaTags {...props.pageContext.metaTags} />
      <div className='pux-container'>
        <div className={`${rootClass} ${rootClass}-page`}>
          <PuxColumns>
            <PuxMainColumn>
              <div className={`${rootClass}-grid`}>
                <div className={`${rootClass}-text`}>
                  <h1 className={`${rootClass}-title`}>
                    {pageData.displayText}
                  </h1>
                  <div className={`${rootClass}-perex perex`}>
                    <PuxWysiwyg content={pageData.functionPerex} />
                  </div>
                </div>
                <div className={`${rootClass}-image`}>
                  {renderMediaComponent()}
                </div>
                <div className={`${rootClass}-content`}>
                  <PuxWysiwyg content={pageData.functionContent} />
                </div>
                <div className={`${rootClass}-gallery`}>
                  <PuxLightboxGallery {...pageData.functionGallery} />
                </div>
              </div>
              <WidgetBuilder
                widgetBuilderData={props.pageContext?.widgets?.widgetZone}
              />
            </PuxMainColumn>
            <PuxSideColumn>
              <div className='sidebar-sticky'>
                <WidgetBuilder
                  widgetBuilderData={props.pageContext.sideCardWidgets}
                />
              </div>
            </PuxSideColumn>
          </PuxColumns>
        </div>
      </div>
    </Layout>
  )
}

export default FunctionDetail
